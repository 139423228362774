import { MetaFunction, useRouteLoaderData, useNavigate, useLocation } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import type { RootLoaderData } from "~/root";
import { IconFooter } from "~/components/layout/IconFooter";
import { generateMeta } from "~/utils/meta";

export const meta: MetaFunction = ({ matches }) => {
    const rootData = matches[0].data as RootLoaderData;

    return generateMeta({
        title: "About",
        description: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!",
        keywords: "Professional events, SDR tool, event networking, find relevant people, event connections, professional networking software, sales research tool",
        baseUrl: rootData?.baseUrl ? `${rootData.baseUrl}/about` : undefined,
        appTitle: rootData?.appTitle,
    });
};

export default function About() {
    const rootData = useRouteLoaderData<RootLoaderData>("root");
    const appTitle = rootData?.appTitle ?? "Our Platform";
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="flex flex-col min-h-screen bg-white">
            <main className="flex-1 overflow-y-auto animate-in fade-in duration-700 slide-in-from-bottom-4">
                {/* Hero Section */}
                <section className="relative h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-white">
                    <div className="text-center px-4 max-w-4xl mx-auto">
                        <h1 className="text-6xl font-semibold tracking-tight mb-6">
                            Meet the right people.
                            <br />
                            Every time.
                        </h1>
                        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                            {`${appTitle} transforms how you network at professional events, helping you connect with the people who matter most to your success.`}
                        </p>
                        <a href="/login">
                            <Button variant="landing" className="px-8 py-4 text-lg">
                                Get Started
                            </Button>
                        </a>
                    </div>
                </section>

                {/* Feature Section */}
                <section className="py-32 px-4 bg-black text-white">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-5xl font-semibold text-center mb-24">How it works</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
                            <div className="text-center">
                                <div className="text-3xl mb-4">1. Upload</div>
                                <p className="text-gray-400">Simply provide the event page or guest list. We handle the rest.</p>
                            </div>
                            <div className="text-center">
                                <div className="text-3xl mb-4">2. Enrich</div>
                                <p className="text-gray-400">We create detailed professional profiles with relevant tags.</p>
                            </div>
                            <div className="text-center">
                                <div className="text-3xl mb-4">3. Connect</div>
                                <p className="text-gray-400">Search and identify the right people during the event.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Pricing Section */}
                <section className="py-32 px-4">
                    <div className="max-w-6xl mx-auto text-center">
                        <h2 className="text-5xl font-semibold mb-16">Simple pricing</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
                            <div className="rounded-3xl p-12 bg-gray-50">
                                <h3 className="text-2xl font-semibold mb-4">Free</h3>
                                <div className="text-5xl font-medium mb-8">$0<span className="text-xl text-gray-500">/mo</span></div>
                                <ul className="text-left space-y-4 mb-8">
                                    <li>• 1 credit per month</li>
                                    <li>• Up to 50 people per event</li>
                                    <li>• Basic search features</li>
                                </ul>
                                <a href="/login">
                                    <Button variant="outline" className="w-full rounded-full">Get Started</Button>
                                </a>
                            </div>
                            <div className="rounded-3xl p-12 bg-black text-white">
                                <h3 className="text-2xl font-semibold mb-4">Pro</h3>
                                <div className="text-5xl font-medium mb-8">$20<span className="text-xl text-gray-400">/mo</span></div>
                                <ul className="text-left space-y-4 mb-8">
                                    <li>• 10 credits per month</li>
                                    <li>• Up to 500 people total</li>
                                    <li>• Advanced search features</li>
                                    <li>• Priority support</li>
                                </ul>
                                <a href="/login">
                                    <Button className="w-full bg-white text-black hover:bg-gray-100 rounded-full">Upgrade Now</Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <IconFooter
                icons={[
                    { id: 'explore', route: '/explore' },
                    { id: 'about', route: '/about' },
                    { id: 'support', route: '/support' },
                    { id: 'login', route: '/login' }
                ]}
                selectedIcon="about"
                navigate={navigate}
                location={location}
            />
        </div>
    );
}
